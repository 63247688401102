import React from 'react'
import { Overview as TagsOverview, Tag } from 'components/tag'
import styled from '@emotion/styled'
import { css } from '@emotion/css'
import Search from './Search'

const tagsContainer = css`
  overflow-x: auto;
  display: flex;
  align-items: center;
  height: 3rem;
  position: relative;

  div,
  span {
    margin: 0;
    margin-left: 0.5rem;
  }
`

const tagsOverview = css`
  margin-bottom: 1rem;
  display: inline-block;
`

const ResetTag = styled(Tag)`
  background: white;
  border: 1px solid ${props => props.theme.colors.neutral.base};

  & a {
    color: black !important;
  }
`

const SearchWithTags = props => (
  <Search {...props}>
    <div className={tagsContainer}>
      Tags:{' '}
      <TagsOverview className={tagsOverview}>
        <ResetTag path="/" title="Alle" />
      </TagsOverview>
    </div>
  </Search>
)

export default SearchWithTags
