import React from 'react'
import styled from '@emotion/styled'
import { useSiteMetadata } from 'hooks'
import { Link } from 'gatsby'

const Wrapper = styled.footer`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  position: relative;
  background: ${props => props.theme.colors.black};
  color: white;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    z-index: 1;
    background: #12c2e9;
    background: linear-gradient(
      ${props => props.theme.colors.gradients.colorful}
    );
  }
`

const List = styled.ul`
  display: flex;
  padding: 1.5rem 0;
  padding-bottom: 1rem;
  margin: 0 1.5rem;
  font-size: ${props => props.theme.fontSizes.sm};
  flex-direction: column;

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    flex-direction: row;
    padding-bottom: 1.5rem;
  }
`

const Item = styled.li`
  position: relative;
  margin-right: 1.5rem;
  margin-bottom: 1rem;

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    margin-bottom: 0;
  }

  a {
    color: white;
    transition: all 0.2s;
    text-decoration: none;

    &:hover {
      border-bottom: 1px solid white;
      color: ${props => props.theme.colors.highlight};
    }
  }
`

const Copyright = styled.div`
  margin-bottom: 1.5rem;
  font-size: ${props => props.theme.fontSizes.sm};
`

const Footer = () => {
  const { menuLinks } = useSiteMetadata()

  return (
    <Wrapper>
      <List>
        {menuLinks.map(({ name, external, url }) => (
          <Item key={name}>
            {external ? (
              <a href={url} target="_blank" rel="noreferrer">
                {name}
              </a>
            ) : (
              <Link to={url}>{name}</Link>
            )}
          </Item>
        ))}

        <Item>
          <a
            href="https://demv-systems.de/rechtliche-hinweise"
            target="_blank"
            rel="noreferrer"
          >
            Rechtliche Hinweise
          </a>
        </Item>
      </List>
      <Copyright>©2021 DEMV Systems GmbH</Copyright>
    </Wrapper>
  )
}

export default Footer
