import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/css'
import { connectSearchBox } from 'react-instantsearch-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'

const Root = styled.form`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
`

const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`

const SearchInput = styled.input`
  width: 100%;
  outline: none;
  background: white;
  border: 1px solid;
  border-color: ${({ hasFocus }) =>
    hasFocus
      ? props => props.theme.colors.info.base
      : props => props.theme.colors.neutral.base};
  font-size: 1.25rem;
  transition: 100ms;
  padding-left: 2rem;
  height: 2.5rem;
`

const searchIconStyles = css`
  width: 1.5rem;
  margin: auto;
  pointer-events: none;
  position: absolute;
  left: 0.5rem;
  top: 0;
  bottom: 0;
`

export default connectSearchBox(
  ({ refine, currentRefinement, className, onFocus }) => (
    <Root className={className}>
      <SearchWrapper>
        <FontAwesomeIcon
          icon={faSearch}
          className={searchIconStyles}
          style={{ width: '1.5rem' }}
        />
        <SearchInput
          className="SearchInput"
          type="text"
          placeholder="Was suchst Du?"
          aria-label="Was suchst Du?"
          onChange={e => refine(e.target.value)}
          value={currentRefinement}
          onFocus={onFocus}
        />
      </SearchWrapper>
    </Root>
  )
)
