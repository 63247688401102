import React from 'react'
import styled from '@emotion/styled'

const BaseTags = styled.div``

const Tags = ({ children, ...props }) => (
  <BaseTags {...props}>{children}</BaseTags>
)

export default Tags
