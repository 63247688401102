import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Tag, Tags } from 'components/tag'

const Overview = props => {
  const data = useStaticQuery(graphql`
    query NonPageQuery {
      allContentfulTag {
        nodes {
          title
          slug
        }
      }
    }
  `)
  const tags = data.allContentfulTag.nodes
  const { children } = props

  return (
    <Tags {...props}>
      {children}
      {tags.map((data, index) => (
        <Tag key={index} {...data} />
      ))}
    </Tags>
  )
}

export default Overview
