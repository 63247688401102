import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { cx, css } from '@emotion/css'

const BaseTag = styled.span`
  margin-bottom: 8px;
  margin-right: 4px;
  padding: 4px 12px;
  border-radius: 32px;
  font-size: ${props => props.theme.fontSizes.sm};
  text-transform: uppercase;

  & a {
    color: white;
    text-decoration: none;
  }
`

const development = css`
  background: #6a3093; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #a044ff,
    #6a3093
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #a044ff,
    #6a3093
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`

const design = css`
  background: #ff4b1f; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ff9068,
    #ff4b1f
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ff9068,
    #ff4b1f
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`

const agile = css`
  background: #348f50; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #56b4d3,
    #348f50
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #56b4d3,
    #348f50
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`

const general = css`
  background: #0f2027; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #2c5364,
    #203a43,
    #0f2027
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #2c5364,
    #203a43,
    #0f2027
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`

const marketing = css`
  background: #396afc; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #2948ff,
    #396afc
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #2948ff,
    #396afc
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`

const Tag = ({ slug, title, className, path }) => {
  return (
    <BaseTag
      className={cx(
        className,
        { [agile]: slug === 'agile' },
        { [design]: slug === 'design' },
        { [development]: slug === 'development' },
        { [general]: slug === 'general' },
        { [marketing]: slug === 'marketing' }
      )}
    >
      {path || slug ? (
        <Link to={path || `/tag/${slug}/`}>{title}</Link>
      ) : (
        <>{title}</>
      )}
    </BaseTag>
  )
}

export default Tag
