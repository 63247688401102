import React, { createRef, useState, useMemo } from 'react'
import styled from '@emotion/styled'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch } from 'react-instantsearch-dom'
import Input from './Input'
import Results from './Results'
import { useClickOutside } from 'hooks'

const Root = styled.div`
  position: relative;
  margin: 0 auto;
  margin-top: 2rem;
  padding: 0 1.5rem;

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    width: 50vw;
    max-width: ${props => props.theme.sizes.maxWidth};
  }
`

const Search = ({ indices, children }) => {
  const rootRef = createRef()
  const [query, setQuery] = useState()
  const [hasFocus, setFocus] = useState(false)
  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  )

  useClickOutside(rootRef, () => setFocus(false))

  return (
    <Root ref={rootRef}>
      {children}
      <InstantSearch
        searchClient={searchClient}
        indexName={indices[0].name}
        onSearchStateChange={({ query }) => setQuery(query)}
      >
        <Input onFocus={() => setFocus(true)} hasFocus={hasFocus} />
        <Results
          show={query && query.length > 0 && hasFocus}
          indices={indices}
        />
      </InstantSearch>
    </Root>
  )
}

export default Search
