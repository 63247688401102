import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { Global } from '@emotion/core'
import Header from './Header'
import Footer from './Footer'
import { globalStyles } from 'styles/globalStyles.js'
import { SearchWithTags } from 'components/search'

const searchIndices = [{ name: `Posts`, title: `Posts` }]

const Root = styled.div`
  font-family: ${props => props.theme.fonts.body};

  #main {
    background-color: ${props => props.theme.colors.neutral.light};
    background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23C9D0D4' fill-opacity='0.25'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    height: 100%;
  }
`

const Skip = styled.a`
  font-family: ${props => props.theme.fonts.bofy};
  padding: 0 1rem;
  line-height: ${props => props.theme.fontSizes.xl};
  background: white;
  color: ${props => props.theme.colors.black};
  z-index: 101;
  position: fixed;
  top: -100%;
  text-decoration: none;

  &:focus,
  &:active,
  &:hover {
    top: 0;
  }
`

const BaseLayout = props => {
  function handleFirstTab(e) {
    if (e.keyCode === 9) {
      document.body.classList.add('user-is-tabbing')
    }
  }
  useEffect(() => window.addEventListener('keydown', handleFirstTab), [])

  return (
    <Root className="siteRoot">
      <div className="siteContent">
        <Skip href="#main" id="skip-navigation">
          Zum Inhalt springen
        </Skip>
        <Header />
        <div id="main">
          <SearchWithTags indices={searchIndices} />
          {props.children}
        </div>
      </div>
      <Footer />
      <Global styles={globalStyles} />
    </Root>
  )
}

export default BaseLayout
