import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { useSiteMetadata } from 'hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHamburger } from '@fortawesome/free-solid-svg-icons/faHamburger'
import logoUrl from '../../../static/images/logo.svg'

const Root = styled.header`
  background: ${props => props.theme.colors.black};
  background: linear-gradient(${props => props.theme.colors.gradients.dark});

  width: 100%;
  padding: 1rem 0;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 4px;
    z-index: 1;
    background: #12c2e9; /* fallback for old browsers */
    background: linear-gradient(
      ${props => props.theme.colors.gradients.colorfulInvert}
    );
  }
`

const Nav = styled.nav`
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  padding: 0 1.5rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    flex-direction: row;
  }

  div {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  a {
    text-decoration: none;
    color: white;
    font-weight: 600;
    transition: all 0.2s;
    padding: 0.5rem;
    margin: -0.5rem;

    @media screen and (min-width: ${props => props.theme.responsive.medium}) {
      color: ${props => props.theme.colors.neutral.base};

      &:hover {
        border-bottom: 1px solid white;
      }
    }
  }
`

const Logo = styled.img`
  height: 32px;
  width: auto;
`

const MenuButton = styled.label`
  color: white;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    display: none;
  }
`

const Menu = styled.ul`
  display: none;
  margin-top: 1rem;

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    margin: 0;
    display: flex;
  }

  li {
    padding: 0.5rem 0;
    white-space: nowrap;

    @media screen and (min-width: ${props => props.theme.responsive.medium}) {
      &:not(&:last-of-type) {
        margin-right: 1.25rem;
      }
    }
  }
`

const MenuToggle = styled.input`
  display: none;

  &:checked + ${Menu} {
    display: block;
  }
`

const activeLinkStyle = {
  color: 'white',
}

const Header = () => {
  const { menuLinks } = useSiteMetadata()

  useEffect(() => {
    window.addEventListener(
      'resize',
      function() {
        if (window.matchMedia(`(min-width: 800px`).matches) {
          document.getElementById('menu-toggle').checked = false
        }
      },
      true
    )

    return () => {
      window.removeEventListener('resize', () => {})
    }
  }, [])

  return (
    <Root>
      <Nav>
        <div>
          <Link to="/" style={{ textdecoration: 'none', border: '0' }}>
            <Logo src={logoUrl} alt="DEMV Systems Logo" />
          </Link>

          <MenuButton htmlFor="menu-toggle">
            <FontAwesomeIcon icon={faHamburger} />
          </MenuButton>
        </div>

        <MenuToggle type="checkbox" id="menu-toggle" />
        <Menu>
          {menuLinks.map(({ name, external, url }) => (
            <li key={name}>
              {external ? (
                <a
                  href={url}
                  activestyle={activeLinkStyle}
                  target="_blank"
                  rel="noreferrer"
                >
                  {name}
                </a>
              ) : (
                <Link to={url} activestyle={activeLinkStyle}>
                  {name}
                </Link>
              )}
            </li>
          ))}
        </Menu>
      </Nav>
    </Root>
  )
}

export default Header
