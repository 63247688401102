import styled from '@emotion/styled'
import { css } from '@emotion/css'
import { Link } from 'gatsby'
import React from 'react'
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
  Snippet,
  PoweredBy,
} from 'react-instantsearch-dom'

const PageHitContainer = styled.div`
  a {
    color: ${props => props.theme.colors.black};
  }
`

const hitsStyles = css`
  ul {
    list-style: none;
    margin-left: 0;
  }

  li.ais-Hits-item {
    margin-bottom: 1rem;

    a {
      color: ${props => props.theme.colors.black};
      text-decoration: none;

      h4 {
        margin-bottom: 0.2rem;
      }

      .ais-Highlight-highlighted {
        background: ${props => props.theme.colors.warning.light};
      }
    }
  }
`

const poweredByStyles = css`
  display: flex;
  justify-content: flex-end;
  font-size: 80%;
  align-items: center;

  svg {
    width: 60px;
    margin-left: 0.5rem;
  }
`

const hitCountStyles = css`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
`

const Root = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  background: white;
  padding: 1rem;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  z-index: 999;
  top: 100%;
  left: 0;
  right: 0;
  margin: auto;
  width: auto;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0.2);
  margin: 0 1.5rem;

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    max-width: ${props => props.theme.sizes.maxWidth};
  }
`

const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits
  return hitCount > 0 ? (
    <div className={hitCountStyles}>
      <small>
        {hitCount} Ergebnis{hitCount !== 1 ? `sse` : ``}
      </small>
    </div>
  ) : (
    <div>
      <p>
        <strong>
          Oops, zu "{searchResults && searchResults.query}" haben wir keine
          Artikel.
        </strong>
      </p>
      <p>
        Aber{' '}
        <a href="https://demv-systems.de/jobs" target="_blank" rel="noreferrer">
          bewirb Dich
        </a>{' '}
        doch einfach, wenn Du uns mehr davon erzählen möchtest! 🚀
      </p>
    </div>
  )
})

const PageHit = ({ hit }) => {
  return (
    <PageHitContainer>
      <Link to={`/${hit.slug}`}>
        <h4>
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </h4>
        <small>{hit.body.childMarkdownRemark.excerpt}</small>
      </Link>
      <Snippet attribute="excerpt" hit={hit} tagName="mark" />
    </PageHitContainer>
  )
}

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <HitCount className={hitCountStyles} />
    <Hits className={hitsStyles} hitComponent={PageHit} />
  </Index>
)

const Results = ({ indices, className, show }) => (
  <Root className={className} show={show}>
    {indices.map(index => (
      <HitsInIndex index={index} key={index.name} />
    ))}
    <PoweredBy className={poweredByStyles} />
  </Root>
)

export default Results
